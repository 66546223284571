<template>
  <vx-card class="edit-bank-page"
           :class="{'main-box top-zero-radius p-2': bankId === 0, 'no-box-shadow': bankId > 0}">
    <div class="edit-bank-fields">
      <custom-validate-input class="w-full"
                             :label="`${$t('treasury.banks.labels.name')}`"
                             :invalid-text="validation.name"
                             :autocomplete="false"
                             align="right"
                             rtl
                             :name="Math.random()"
                             v-model="bank.name"
                             @keydown.enter="sendData"/>

      <custom-number-input class="w-full"
                           :label="`${$t('treasury.banks.labels.accountNumber')}`"
                           :invalid-text="validation.account_number"
                           :autocomplete="false"
                           is-empty
                           :name="Math.random()"
                           v-model="bank.account_number"
                           @keydown.enter="sendData"/>

      <custom-card-number-input class="w-full"
                                :label="`${$t('treasury.banks.labels.cardNumber')}`"
                                :danger-text="validation.card_number"
                                is-empty
                                :autocomplete="false"
                                :name="Math.random()"
                                v-model="bank.card_number"
                                @keydown.enter="sendData"/>

      <custom-number-input class="w-full"
                           :label="`${$t('treasury.banks.labels.shebaNumber')}`"
                           :invalid-text="validation.shaba_number"
                           :autocomplete="false"
                           is-empty
                           :name="Math.random()"
                           v-model="bank.shaba_number"
                           @keydown.enter="sendData"/>

      <vs-button id="editBankBTN"
                 class="useral-action-button"
                 @click="sendData()"/>

      <vs-button id="deleteBankBTN"
                 v-if="checkUserPermissions('bank.delete')"
                 class="w-full mt-5"
                 color="danger"
                 @click="$refs.deleteConfirmation.showDialog()">{{$t('treasury.banks.labels.delete')}}</vs-button>
    </div>

    <custom-dialog ref="deleteConfirmation"
                   :title="`${$t('treasury.banks.edit.confirmations.delete.title')}`"
                   :body="`${$t('treasury.banks.edit.confirmations.delete.body', {bank: bank.name})}`"
                   @accept="deleteBank"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {deleteBank, editBank, getBank} from '@/http/requests/treasury/banks'
import CustomDialog from '@/components/customDialog/customDialog'
import {checkUserPermissions, hideLoading, showLoading} from "../../../../../../assets/js/functions";
import CustomValidateInput from "@/components/customInput/customValidateInput.vue";
import CustomNumberInput from "@/components/customInput/customNumberInput.vue";
import CustomCardNumberInput from "@/components/customInput/customCardNumberInput.vue";

export default {
  name: 'editBank',
  components: {CustomCardNumberInput, CustomNumberInput, CustomValidateInput, CustomDialog},
  metaInfo () {
    return {
      title: !this.bank.name ? this.$t('treasury.banks.edit.title') : this.$t('treasury.banks.edit.dynamicTitle', {bank: this.bank.name.value})
    }
  },
  props: {
    bankId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      firstChange: true,
      bank: {
        id: 0,
        name: {
          value: '',
          isValid: true
        },
        account_number: {
          value: '',
          isValid: true
        },
        card_number: {
          value: '',
          isValid: true
        },
        shaba_number: {
          value: '',
          isValid: true
        }
      },
      bankCopy: {},
      validation: {
        name: '',
        account_number: '',
        card_number: '',
        shaba_number: ''
      },
      actions: [
        {
          toolbar: [
            {
              id: 'editBankBTN',
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success',
              permission: 'bank.update'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'editTreasuryBank' ? 'treasuryBank' : 'treasuryBanks'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
    }, 50)

    showLoading()

    this.getBank()
  },
  methods: {
    addDash (val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{4})+(?!\d))/g, '-') + sign
    },
    isNumber (val) {
      return val.toString().replace(/[^0-9]/g, '')
    },
    getBank () {
      this.bank.id = this.$route.params.id
      getBank(this.bankId || this.$route.params.id).then(response => {
        const bank = response.data.data
        this.bank = {
          id: bank.id,
          name: {
            value: bank.name,
            isValid: true
          },
          account_number: {
            value: bank.account_number || '',
            isValid: true
          },
          card_number: {
            value: bank.card_number || '',
            isValid: true
          },
          shaba_number: {
            value: bank.shaba_number || '',
            isValid: true
          }
        }
        this.bankCopy = JSON.parse(JSON.stringify(this.bank))
        this.$store.dispatch('setPageTitle', this.$t('treasury.banks.edit.dynamicTitle', {bank: this.bank.name.value}))
        hideLoading()
      })
        .catch(error => {
          hideLoading()
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    sendData () {
      this.nameValidator()

      if (!this.validation.name &&
        !this.validation.account_number &&
        !this.validation.card_number &&
        !this.validation.shaba_number &&
        !(this.bank.card_number.value === '' &&
          this.bank.account_number.value === '' &&
          this.bank.shaba_number.value === '')) {
        const bank = {
          id: this.bank.id,
          name: this.bank.name.value,
          account_number: this.bank.account_number.value || null,
          card_number: this.bank.card_number.value || null,
          shaba_number: this.bank.shaba_number.value || null
        }

        if (this.bankCopy.name.value === bank.name) delete bank.name

        /*if (this.bankCopy.account_number === bank.account_number) delete bank.account_number

        if (this.bankCopy.card_number === bank.card_number) delete bank.card_number
        else bank.card_number = bank.card_number.replaceAll('-', '')

        if (this.bankCopy.shaba_number === bank.shaba_number) delete bank.shaba_number*/

        editBank(bank).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('treasury.banks.edit.notifications.edit.success'),
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success'
          })

          this.$store.dispatch('removePageChanges')
          // this.$router.push({name: 'treasuryBank', params: {id: this.$route.params.id}})
          this.$router.push({name: 'treasuryBanks'})
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.banks.edit.notifications.edit.error'),
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          }
        })
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.banks.edit.notifications.edit.error'),
          time: 2400,
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          color: 'danger'
        })
      }
    },
    deleteBank () {
      deleteBank(this.bankId || this.$route.params.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.banks.edit.notifications.delete.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        this.$store.dispatch('removePageChanges')
        this.$router.push({name: 'treasuryBanks'})
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.banks.edit.notifications.delete.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    },
    nameValidator () {
      this.validation.name = (this.bank.name.value || this.bankCopy.name.value).toString().length >= 2 ? '' : 'نام بانک به صورت صحیح وارد نشده است'
    },
    accountNumberValidator () {
      if (this.$validator('regex.treasury.bank.accountNumber').test(this.bank.account_number.value) || this.bank.account_number.value === '') {
        this.validation.account_number = ''
        if (this.bank.card_number.value === '') {
          this.validation.card_number = ''
        }
        if (this.bank.shaba_number.value === '') {
          this.validation.shaba_number = ''
        }
      } else {
        this.bank.account_number.isValid = false
        this.validation.account_number = this.$t('treasury.banks.validators.accountNumber')
      }
    },
    cardNumberValidator () {
      const card = (this.bank.card_number.value || this.bankCopy.card_number.value).toString().replaceAll('-', '')
      if ((typeof card === 'undefined'
        || card === null
        || card.length !== 16)
        && card !== '') {
        this.validation.card_number = this.$t('treasury.banks.validators.cardNumber')
      } else {
        this.validation.card_number = ''
        if (this.bank.account_number.value === '') {
          this.validation.account_number = ''
        }
        if (this.bank.shaba_number.value === '') {
          this.validation.shaba_number = ''
        }
      }
    },
    shabaNumberValidator () {
      if (this.$validator('regex.treasury.bank.shabaNumber').test(this.bank.shaba_number.value) || this.bank.shaba_number.value === '') {
        this.validation.shaba_number = ''
        if (this.bank.account_number.value === '') {
          this.validation.account_number = ''
        }
        if (this.bank.card_number.value === '') {
          this.validation.card_number = ''
        }
      } else {
        this.bank.shaba_number.isValid = false
        this.validation.shaba_number = this.$t('treasury.banks.validators.shebaNumber')
      }
    },
    checkUserPermissions (permission) {
      return checkUserPermissions(permission)
    }
  },
  watch: {
    'bank': {
      handler (val, oldVal) {

        if (!this.firstChange) this.$store.dispatch('setPageChanges')

        if (Object.keys(oldVal).length > 0 && JSON.stringify(val)) {
          this.firstChange = false
        }
      },
      deep: true
    },
    'bank.name.value': {
      handler () {
        this.nameValidator()
      }
    }
  }
}
</script>

<style lang="scss">
.edit-bank-page {
  .edit-bank-fields {
    max-width: 400px;
  }

  .dir-rtl {

    input {
      direction: rtl;
    }
  }
}
</style>

